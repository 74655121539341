<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="true">大单元知识建构</public-header>
    </el-header>
    <ul class="tab">
      <li v-for="(item, index) in list" :key="item.id" :class="{ active: index == currentIndex }"
        @click="changeIndex(index)">{{ item.label }}</li>
    </ul>
    <el-main>
      <div class="card-container" v-for="(item, index) in childrenList" :key="index">
        <v-card class="rounded-xl" @click="beginTest(item.id, trainSetId)">
          <color-title color="#759BFF">
            {{ item.label }}
            <span style="float: right;">
              {{ nodeInfo[item.id].questionNumFinished }}
              /
              {{ nodeInfo[item.id].questionNumAll }}
            </span>
          </color-title>
          <div class="num-block">
            <p>{{ getCorrectRate(item.id) }}</p>
            <p>正确率</p>
          </div>
          <div class="num-block">
            <p>{{ getCostTime(item.id) }}</p>
            <p>答题用时</p>
          </div>
          <button v-if="nodeInfo[item.id].questionNumFinished" class="btn-blue" @click.stop="goPreview(item.id)">
            <div>
              <span>查看</span>
              <br />
              <span>答题</span>
            </div>
          </button>
        </v-card>
      </div>
    </el-main>
  </el-container>
</template>
<script>
// import loadMore from '@/components/loadMore/loadMore'
export default {
  components: {
    // loadMore,
  },
  data() {
    return {
      list: [],
      currentIndex: 0,
      nodeInfo: []
    }
  },
  computed: {
    famousTeacherId() {
      return Number(this.$route.query.famousTeacherId)
    },
    trainSetId() {
      return Number(this.$route.query.trainSetId)
    },
    childrenList() {
      if (this.list.length) {
        return this.list?.[this.currentIndex]?.children || []
      } else {
        return []
      }
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    queryList() {
      const params = {
        famousTeacherId: this.famousTeacherId,
        trainSetId: this.trainSetId
      }
      this.$service.getTrainSetInfo(params).then((res) => {
        // const topicList = this.$store.state.topicList
        let result = res.tree.tree;
        this.list = result;
        console.log(result)
        this.nodeInfo = res.nodeInfo;
        // for (let i of topicList) {
        //   result.push(res.list[i])
        // }
        // 节选
        // this.$refs.more.complete(result)
      })
    },
    // 开始测试
    beginTest(id, topId) {
      if (this.nodeInfo[id].questionNumFinished < this.nodeInfo[id].questionNumAll) {
        this.$router.push({
          name: 'StartErrorTesting',
          query: {
            type: 'teacher',
            id,
            topId,
          },
        })
      } else {
        this.goPreview(id);
      }

    },
    // 查看已完成试题
    goPreview(id) {
      this.$router.push({
        name: 'PreviewTesting',
        query: {
          type: 'teacherFinish',
          id,
        },
      })
    },
    //计算正确率
    getCorrectRate(id) {
      if (this.nodeInfo[id].questionNumFinished == 0) {
        return '--'
      }
      let rate = (this.nodeInfo[id].questionNumCorrect / this.nodeInfo[id].questionNumFinished) * 100 || 0;
      return Number(rate.toString().match(/^\d+(?:\.\d{0,2})?/)) + '%';
    },
    //计算花费时间
    getCostTime(id) {
      let h, m, s
      let seconds = this.nodeInfo[id].costTime;
      if (seconds == 0) {
        return '--'
      } else {
        h = parseInt(seconds / 3600);
        m = parseInt((seconds - h * 3600) / 60);
        s = parseInt(seconds - h * 3600 - m * 60);
        if (h > 0 && h < 10) {
          h = '0' + h
        }
        if (m < 10) {
          m = '0' + m
        }
        if (s < 10) {
          s = '0' + s
        }
        return h ? h + ':' + m + ':' + s : m + ':' + s
      }
    },
    //改变父级选中
    changeIndex(index) {
      this.currentIndex = index;
    },
  },
}
</script>
<style lang="scss" scoped>
.adaptive-testing {
  .el-main {
    height: calc(100% - 100px);
    margin-left: -32px;
  }

  .card-container {
    width: calc(50% - 32px);
    margin-left: 32px;
    margin-bottom: 32px;
    display: inline-block;
    height: 210px;
  }

  .v-card {
    padding: 24px 32px;

    .label {
      font-size: 20px;
    }

    .num-block {
      display: inline-block;
      width: 140px;

      p:first-of-type {
        font-size: 36px;
        color: #777;
        margin-bottom: 14px;
        margin-top: 24px;
        text-align: center;
        line-height: 48px;
        font-family: Akrobat-Black;
      }

      p:nth-of-type(2) {
        font-size: 18px;
        color: #999;
        margin-bottom: 0;
        text-align: center;
        font-family: PingFangSC-Regular;
      }
    }

  }

  .tab {
    height: 60px;
    padding: 10px 24px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    li {
      display: inline-block;
      height: 40px;
      padding: 6px 16px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;

      &.active {
        background: #759BFF;
        border-radius: 10px;
        color: #fff;
      }
    }
  }

  .btn-blue {
    position: absolute;
    right: 18px;
    bottom: 38px;
    display: inline-block;
    width: 68px;
    height: 68px;
    background: #F1F5FF;
    border-radius: 14px !important;
    color: #759BFF;
  }
}
</style>